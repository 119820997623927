/*
 * (C) Symbol Contributors 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and limitations under the License.
 *
 */
import { Validator, staticImplements } from './Validator';

@staticImplements<Validator>()
export class UrlValidator {
    /**
     * Validates an URL
     * @static
     * @param {*} value
     * @returns {boolean}
     */
    public static validate(value: string): boolean {
        const regStr =
            '^' + // position at start
            '(https?:\\/\\/)?' + //protocol
            '((([a-zA-Z\\d]{1,}([-\\.]{1}[a-zA-Z\\d]{1,})*\\.[a-zA-Z]+)' + // domain name
            '|((\\d{1,3}\\.){3}\\d{1,3}))' + // ip(v4) address
            '(\\:\\d+)?)' + // port
            '|localhost\\:\\d+'; // localhost:8080
        const pattern = new RegExp(regStr, 'i');
        return pattern.test(value);
    }
}
