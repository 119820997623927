



import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters({
            message: 'app/loadingOverlayMessage',
            disableCloseButton: 'app/loadingDisableCloseButton',
        }),
    },
})
export default class SpinnerLoading extends Vue {
    /**
     * Message displayed when UI is disabled
     * @var {string}
     */
    public message: string;

    /**
     * Whether should show the close button
     * @var {boolean}
     */
    public disableCloseButton: boolean;

    open() {
        // @ts-ignore
        this.$Spin.show({
            render: (h) => {
                return h('div', [
                    h(
                        'div',
                        {
                            class: 'loading-overlay-message',
                        },
                        this.message,
                    ),
                    !this.disableCloseButton
                        ? h('i', {
                              class: 'ivu-icon ivu-icon-ios-close-circle icon close-icon',
                              size: '45px',
                          })
                        : '',
                    !this.disableCloseButton
                        ? h(
                              'a',
                              {
                                  on: { click: this.closeScreen },
                                  class: 'close-text',
                              },
                              'close',
                          )
                        : '',
                ]);
            },
        });

        this;
    }

    mounted() {
        this.open();
    }

    closeScreen() {
        this.$store.dispatch('app/SET_LOADING_OVERLAY', {
            show: false,
            message: '',
        });
    }
}
