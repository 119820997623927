// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../views/resources/fonts/NotoSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../views/resources/fonts/NotoSans-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../views/resources/fonts/NotoSans-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../views/resources/fonts/NotoSans-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../views/resources/fonts/NotoSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../views/resources/fonts/NotoSans-SemiBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:noto-sans-bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-extrabold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-light;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-medium;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-regular;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-semibold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");font-weight:400;font-style:normal}.demo-spin-icon-load{-webkit-animation:ani-demo-spin 1s linear infinite;animation:ani-demo-spin 1s linear infinite}.ivu-spin-dot{display:block!important;position:relative;margin:0 auto .3rem;display:block;background-color:#44004e;width:.5rem;height:.5rem;-webkit-animation:ani-spin-bounce 1s ease-in-out 0s infinite;animation:ani-spin-bounce 1s ease-in-out 0s infinite;border-radius:50%}.close-icon{margin-right:.04rem;font-size:.2rem;color:#000}.loading-overlay-message{font-size:.2rem;margin-bottom:.2rem;color:#44004e}.close-text{font-size:.2rem;color:#000;text-decoration:none}", ""]);
// Exports
module.exports = exports;
