



















import 'animate.css';
import { AppTs } from '@/app/AppTs.ts';
export default class App extends AppTs {}
